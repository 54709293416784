<template>
  <div class="booking-table">
    <!-- <p class="booking-table-title">Your upcoming bookings</p> -->
    <el-table
      v-if="innerWidth > 980"
      :data="tableData"
      height="250"
      class="table g-radius-4"
    >
      <template v-for="(item, index) in column">
        <el-table-column
          v-if="item.prop === 'options'"
          :key="index"
          :prop="item.prop"
          label=""
          :width="item.width"
        >
          <template class="options-box" slot-scope="scope">
            <el-button
              type="primary"
              v-if="scope.row.status === 1"
              size="small"
              @click="pay(scope.$index)"
              >Pay</el-button
            >
            <el-button type="primary" size="small" @click="change(scope.$index)"
              >Change</el-button
            >
            <el-button type="primary" size="small" @click="cancel(scope.$index)"
              >Cancel</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          v-else-if="item.prop === 'comment'"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        >
          <template slot-scope="scope">
            <el-popover
              v-if="scope.row.comment"
              placement="top"
              trigger="click"
              :content="scope.row.comment"
              width="200"
            >
              <el-link slot="reference">View</el-link>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          v-else
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :align="item.align"
          :width="item.width"
        />
      </template>
    </el-table>
    <div v-else class="card-box g-radius-4 g-scroll-style">
      <div
        class="card-item g-radius-4 g-hover-pointer"
        v-for="(item, index) in tableData"
        :key="index"
      >
        <div class="line">
          <span class="key">Event</span>
          <span class="val">{{ item.event }}</span>
        </div>
        <div class="line">
          <span class="key">Fee</span>
          <span class="val">{{ item.fee }}</span>
        </div>
        <!--                <div class="line">-->
        <!--                  <span class="key">Status</span>-->
        <!--                  <span class="val">{{ item.status }}</span>-->
        <!--                </div>-->
        <div class="line">
          <span class="key">Time</span>
          <span class="val">{{ item.date }} {{ item.time }}</span>
        </div>
        <div class="line btn-box" v-if="status == '1' || status == '2'">
          <el-button
            type="primary"
            v-if="item.status === 1"
            size="small"
            @click="pay(index)"
            >Pay</el-button
          >
          <el-button type="primary" size="small" @click="change(index)"
            >Change</el-button
          >
          <el-button type="primary" size="small" @click="cancel(index)"
            >Cancel</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookingTable",
  props: {
    // 类型 0 1 2
    // tableData: [
    //     {
    //       event: "Move In",
    //       date: "17/08/2021",
    //       time: "11:30am -13:30pm",
    //       status: "Paid",
    //       fee: "Free",
    //     },
    //   ],
    status: {
      type: [String],
      required: true,
    },
    tableData: {
      type: [Array],
    },
  },
  data() {
    return {
      innerWidth: window.innerWidth,
    };
  },
  computed: {
    column() {
      //表格标题
      if (this.status === "2") {
        return [
          { prop: "event", label: "Event", align: "left" },
          { prop: "date", label: "Date", width: 150, align: "center" },
          { prop: "time", label: "Time", width: 180, align: "center" },
          { prop: "fee", label: "Fee", align: "center" },
          {
            prop: "apartmentNumberName",
            label: "apartment no",
            width: 150,
            align: "center",
          },
          { prop: "comment", label: "Comment", width: 120, align: "center" },
          { prop: "options", label: "", width: 250 },
        ];
      } else if (this.status === "1") {
        return [
          { prop: "event", label: "Event", align: "left" },
          { prop: "date", label: "Date", width: 150, align: "center" },
          { prop: "time", label: "Time", width: 180, align: "center" },
          { prop: "fee", label: "Fee", align: "center" },
          {
            prop: "apartmentNumberName",
            label: "apartment no",
            width: 150,
            align: "center",
          },
          { prop: "comment", label: "Comment", width: 120, align: "center" },
          { prop: "options", label: "", width: 350 },
        ];
      } else if (this.status === "4") {
        return [
          { prop: "event", label: "Event" },
          { prop: "date", label: "Date", align: "center" },
          { prop: "time", label: "Time", width: 180, align: "center" },
          //{ prop: "status", label: "Status", align: "center" },
          { prop: "fee", label: "Fee", align: "center" },
          {
            prop: "apartmentNumberName",
            label: "apartment no",
            width: 150,
            align: "center",
          },
          { prop: "comment", label: "Comment", width: 120, align: "center" },
        ];
      } else {
        return [
          { prop: "event", label: "Event", align: "left" },
          { prop: "date", label: "Date", align: "center" },
          { prop: "time", label: "Time", width: 180, align: "center" },
          { prop: "fee", label: "Fee", align: "center" },
          {
            prop: "apartmentNumberName",
            label: "apartment no",
            width: 150,
            align: "center",
          },
          { prop: "comment", label: "Comment", width: 120, align: "center" },
        ];
      }
    },
  },
  mounted() {
    // 监听页面宽度变化
    window.addEventListener("resize", this.getWidth, false);
  },
  destroyed() {
    // 取消监听
    window.removeEventListener("resize", this.getWidth, false);
  },
  methods: {
    pay(item) {
      //修改事件调父级
      this.$emit("pay", item);
    },
    change(item) {
      //修改事件调父级
      this.$emit("change", item);
    },
    cancel(item) {
      //取消事件调父级
      this.$emit("cancel", item);
    },
    getWidth() {
      //获取当前宽度
      this.innerWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
