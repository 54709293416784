<template>
  <div class="my-booking-page">
    <m-page-title title="My Booking" address />
    <el-tabs
      class="tabs-box"
      v-model="active"
      type="card"
      @tab-click="myBookTab"
    >
      <el-tab-pane label="Pending" name="1" />
      <el-tab-pane label="Upcoming" name="2" />
      <el-tab-pane label="Completed" name="4" />
      <el-tab-pane label="Cancelled" name="5" />
    </el-tabs>
    <booking-table
      class="booking-table"
      :status="active"
      :tableData="bookingData"
      @change="changeFun($event)"
      @cancel="cancelFun($event)"
      @pay="payFun($event)"
    />
    <div class="booking-page">
      <el-pagination
        background
        layout="prev, pager, next"
        class="page-box"
        :page-size="pagesize"
        :current-page="currentPage1"
        :total="pageTotal"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <m-to-dashboard class="btn" />
    <m-dialog
      v-model="cancelShow"
      mask
      unBack
      cancel-txt="No"
      confirm-txt="Yes"
      :address="popDress"
      :title="popTitle"
      :content="popContent"
      :image="dialogImg"
      @confirm="confirmPopup"
      @cancel="cancelPopup"
    ></m-dialog>
    <m-dialog
      v-model="changeShow"
      mask
      unBack
      confirmHide
      cancel-txt="Ok"
      :address="popDress"
      :title="popTitle"
      :content="popContent"
      :image="dialogImg"
      @cancel="cancelPopup"
    ></m-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BookingTable from "./components/BookingTable";
import { dateVal } from "@/utils/tools";
export default {
  name: "myBooking",
  components: { BookingTable },
  data() {
    return {
      changeShow: false,
      cancelShow: false,
      currentPage1: 1,
      active: "2",
      bookingData: [],
      bookingDataBox: [],
      popDress: "",
      pageTotal: 0,
      popTitle: "That’s it. Cancelled",
      popContent: "",
      pagesize: 10,
      selOrderid: "",
      dialogImg: undefined,
    };
  },
  computed: {
    ...mapState("apartment", ["apartment", "room"]),
    ...mapState("user", ["userInfo"]),
  },
  created() {
    this.Bookingdata(1);
    this.keyupSubmit();
  },
  mounted() {
    console.log("apartment,room", this.apartment, this.room);
  },
  methods: {
    keyupSubmit() {
      document.onkeydown = () => {
        const _key = window.event.keyCode;
        if (_key === 13) {
          if (this.cancelShow) {
            this.confirmPopup();
            return;
          }
          if (this.changeShow) {
            this.cancelPopup();
            return;
          }
        }
      };
    },
    cancelPopup() {
      //弹出框关闭
      this.changeShow = false;
      this.cancelShow = false;
    },
    payFun(index) {
      const orderModel = this.bookingDataBox[index];
      this.$router.push({
        path: "/pay",
        query: {
          orderId: orderModel.id,
          feeType: orderModel.totalAmt === 0 ? 0 : 1,
          optionType: 1,
        },
      });
    },
    confirmPopup() {
      //取消订单
      this.$axios.delete(this.$api.cancelOrder(this.selOrderid)).then((res) => {
        if (res.code === "1000") {
          this.Bookingdata(1);
          this.$notify.success("The booking has been successfully cancelled.");
          this.cancelShow = false;
        }
      });
    },
    isTime(dateone, hour) {
      //判断时间是否满足条件
      dateone = dateone.replace(/-/g, "/");
      let exceptTime = new Date(dateone) - Date.now();
      return exceptTime > parseFloat(hour) * 60 * 60 * 1000;
    },
    queryAdvanceHour(apartmentId, apartmentUnitId, apartmentFloorId) {
      //请求当前apartment设定时间
      let that = this;
      let params = { apartmentUnitId, apartmentFloorId };
      return that.$axios.get(this.$api.getAdvanceHour(apartmentId), { params });
    },
    async cancelFun(index) {
      //取消订单
      let that = this;
      const orderModel = this.bookingDataBox[index];
      this.getApartmentIno(orderModel.apartmentId);
      this.selOrderid = orderModel.id;
      const popContent = "Do you want to cancel this booking?";
      const popTitle = "Confirm cancellation";
      if (orderModel.type === 2) {
        //免费直接可以取消
        that.cancelShow = true;
        that.popTitle = popTitle;
        that.popDress = orderModel.address;
        that.popContent = popContent;
      } else {
        let data = await that.queryAdvanceHour(
          orderModel.apartmentId,
          orderModel.apartmentUnitId,
          orderModel.apartmentFloorId
        );
        if (data.code === "1000") {
          if (data.data.advanceHour === 0) {
            that.cancelShow = true;
            that.popTitle = popTitle;
            that.popDress = orderModel.address;
            that.popContent = popContent;
          } else {
            let create_time =
              orderModel.day + " " + orderModel.startTime + ":00";
            let isoperation = await that.isTime(
              create_time,
              data.data.advanceHour
            );
            if (isoperation) {
              //可以修改时间
              that.cancelShow = true;
              that.popTitle = popTitle;
              that.popDress = orderModel.address;
              that.popContent = popContent;
            } else {
              //不可以修改时间
              that.cancelShow = true;
              that.popTitle = popTitle;
              that.popDress = orderModel.address;
              that.popContent =
                "Are you sure you want to cancel it?  Cancel appointment within 48 hours from the scheduled time, it will be non-refundable.";
            }
          }
          // this.cancelShow = true;
        }
      }
    },
    async changeFun(index) {
      //修改事件
      let that = this;
      const orderModel = this.bookingDataBox[index];
      this.getApartmentIno(orderModel.apartmentId);

      console.log(orderModel);
      if (orderModel.type === 2) {
        this.$router.push({
          path: "/editBooking",
          query: {
            orderId: orderModel.id,
            orderTypeId: orderModel.type,
          },
        });
      } else {
        let data = await that.queryAdvanceHour(
          orderModel.apartmentId,
          orderModel.apartmentUnitId,
          orderModel.apartmentFloorId
        );
        if (data.code === "1000") {
          if (data.data.advanceHour === 0) {
            this.$router.push({
              path: "/editBooking",
              query: {
                orderId: orderModel.id,
                orderTypeId: orderModel.type,
              },
            });
          } else {
            let create_time =
              orderModel.day + " " + orderModel.startTime + ":00";
            let isoperation = await that.isTime(
              create_time,
              data.data.advanceHour
            );
            if (isoperation) {
              //可以修改时间
              this.$router.push({
                path: "/editBooking",
                query: {
                  orderId: orderModel.id,
                  orderTypeId: orderModel.type,
                },
              });
            } else {
              let txt = "";
              if (orderModel.type == 0) {
                txt = "Move In";
              } else if (orderModel.type == 1) {
                txt = "Move Out";
              }
              //不可以修改时间
              that.changeShow = true;
              that.popTitle = "Reschedule " + txt;
              that.popDress = orderModel.address;
              that.popContent =
                "Booking cannot be rescheduled within 48 hours notice. if you have any questions,please contact building manager 03 5623 2365";
            }
          }
          // this.cancelShow = true;
        }
      }
    },
    handleCurrentChange(val) {
      //分页
      this.Bookingdata(val);
    },
    myBookTab() {
      //tab切换事件
      this.bookingData = [];
      this.Bookingdata(1);
    },
    Bookingdata(page) {
      //获取列表数据
      const params = {
        status: this.active,
        apartmentId: this.apartment.apartmentId
          ? this.apartment.apartmentId
          : this.apartment.id,
        pageNum: page,
        pageSize: this.pagesize,
      };
      if (this.room) {
        params.apartmentFloorId = this.room.apartmentFloorId;
        params.apartmentNumberId = this.room.id;
      }
      this.$axios
        .get(this.$api.myBookingData, {
          params,
        })
        .then((res) => {
          if (res.code === "1000") {
            this.bookingData = [];
            this.bookingDataBox = [];
            this.bookingDataBox = res.data.list;
            this.pageTotal = parseInt(res.data.count);
            for (var i = 0; i < res.data.list.length; i++) {
              let bookingdata = {};
              bookingdata.time =
                dateVal(res.data.list[i].startTime) +
                "-" +
                dateVal(res.data.list[i].endTime);
              bookingdata.date = res.data.list[i].day;
              bookingdata.status = res.data.list[i].status;
              bookingdata.fee = "$" + res.data.list[i].totalAmt;
              bookingdata.comment = res.data.list[i].comment;
              // res.data.list[i].totalAmt === 0
              //   ? "Free"
              //   : "$" + res.data.list[i].totalAmt;
              bookingdata.apartmentNumberName = "";
              if (res.data.list[i].type === 2) {
                bookingdata.event =
                  "Communal Facility - " + res.data.list[i].apartmentNumberName;
              } else if (res.data.list[i].type === 0) {
                bookingdata.event = "Move In";
                bookingdata.apartmentNumberName =
                  res.data.list[i].apartmentFloorName +
                  res.data.list[i].apartmentNumberName;
              } else {
                bookingdata.event = "Move Out";
                bookingdata.apartmentNumberName =
                  res.data.list[i].apartmentFloorName +
                  res.data.list[i].apartmentNumberName;
              }

              this.bookingData.push(bookingdata);
            }
          }
        });
    },
    getApartmentIno(apartmentId) {
      this.$axios.get(this.$api.getApartmentIno(apartmentId)).then((res) => {
        this.dialogImg = res.data.photo;
      });
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
